<template>
  <div class="exam-wrap" @mousedown="onMousedown($event)">
    <div class="head-top-Div">
      <img class="head-img" src="@/assets/image/head_bg.png" alt="">
      <div class="head-center">
        <div class="side-top">
          <div class="side-tips-l">
            <img src="@/assets/image/hxclass_font2.png" alt="">
            <p>欢迎来到华夏云课堂考试系统！</p>
          </div>
          <div class="side-time-r">
            <!-- 考试剩余时间 -->
            <span class="time">
              <img src="@/assets/image/time.png" alt="">
              剩余时间
              <i>{{hour}}:{{minute}}:{{second}}</i>
            </span>
            <span class="paper-btn" @click="onSaveCheck()">交 卷</span>
            <!-- <span class="paper-btn" @click="">交 卷</span> -->
          </div>
        </div>
      </div>
    </div>
    <div class="answer-box">
      <!-- 左侧--信息和题号 -->
      <div class="side-number-left">
        <!-- 考生信息 -->
        <h4 class="title">考生信息</h4>
        <div class="info-inner">
          <p class="line"><span>姓名：</span>{{$store.state.userInfo.name}}</p>
          <p class="line"><span>证件号码：</span>{{$store.state.userInfo.idcard}}</p>
        </div>
        <!-- <h4 class="title" style="margin-top:15px;">考试提醒</h4> -->
        <div class="info-inner">
          <p class="con" v-for="(item,index) in noticeList" :key="index">
            {{index + 1}}.{{item}}
          </p>
        </div>
        <div class="answer-sheet">
          <h4 class="title">答题卡</h4>
          <div class="state">
            <span>已答</span>
            <span>未答</span>
          </div>
        </div>
        <!-- 题号 -->
        <div class="title-number">
          <!-- 单选题 -->
          <div class="number-inner" v-if="paperForm.radioList.length">
            <div class="topic-type">
              <span class="type">单选题</span>
              <span>{{radioSelectTotal}}/{{radioTotal}}</span>
            </div>
            <div class="list">
              <span class="item" 
                @click="onAnchor('radioItem',item.libraryId)"
                :class="{'item-active' : libraryIdList.indexOf(item.libraryId) != -1 }" 
                v-for="(item,index) in paperForm.radioList"
                :key="item.libraryId + 'radio'">
              {{index + 1}}</span>
            </div>
          </div>
          <!-- 多选题 -->
          <div class="number-inner" v-if="paperForm.checkedList.length">
            <div class="topic-type">
              <span class="type">多选题</span>
              <span>{{checkedSelectTotal}}/{{checkedTotal}}</span>
            </div>
            <div class="list">
              <span class="item" 
              @click="onAnchor('checkedItem',item.libraryId)"
              :class="{'item-active' : libraryIdList.indexOf(item.libraryId) != -1}" 
              v-for="(item,index) in paperForm.checkedList"
              :key="item.libraryId + 'checke'">
            {{radioTotal + index + 1}}</span>
            </div>
          </div>
          <!-- 判断题 -->
          <div class="number-inner" v-if="paperForm.switchList.length">
            <div class="topic-type">
              <span class="type">判断题</span>
              <span>{{switchSelectTotal}}/{{switchTotal}}</span>
            </div>
            <div class="list">
              <span class="item" 
              @click="onAnchor('switchItem',item.libraryId)"
              :class="{'item-active' : libraryIdList.indexOf(item.libraryId) != -1}" 
              v-for="(item,index) in paperForm.switchList"
              :key="item.libraryId + 'switch'">
            {{radioTotal + checkedTotal + index + 1}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧--作答区 -->
      <div class="side-topic-left">
        <div class="system-inner">
          <!-- 单选题 -->
          <div class="topic-list" v-if="paperForm.radioList.length">
            <div class="topic-item-title" v-html="paperForm.radioTitle"></div>
            <div class="question-item" :id="'radioItem' + item.libraryId" v-for="(item,index) in paperForm.radioList" :key="item.libraryId + 'radioItem'">
              <p class="question-name">{{index + 1}}. {{item.title}}</p>
              <div class="option-child">
                <!-- <a-radio-group> -->
                  <a-radio class="option-item"
                    :disabled="paperDisabel || !network"
                    :checked="childItem.isCheked==1"
                    @click="onSingleChoice(item.libraryId, item.optionVoList, childItem, item)"
                    :value="i" 
                    v-for="(childItem,i) in item.optionVoList" 
                    :key="item.libraryId +'-' + childItem.optionId">
                    <span>{{childItem.letter}}、{{childItem.optionName}}</span>
                  </a-radio>
                <!-- </a-radio-group> -->
              </div>
            </div>
          </div>
          <!-- 多选题 -->
          <div class="topic-list" v-if="paperForm.checkedList.length">
            <div class="topic-item-title" v-html="paperForm.checkedTitle"></div>
            <div class="question-item" :id="'checkedItem' + item.libraryId" v-for="(item,index) in paperForm.checkedList" :key="item.libraryId + 'checkedItem'">
              <p class="question-name">{{radioTotal + index + 1}}. {{item.title}}</p>
              <div class="option-child">
                <!-- <a-checkbox-group> -->
                  <a-checkbox 
                    class="option-item"
                    :disabled="paperDisabel || !network"
                    :checked="childItem.isCheked==1"
                    @click="onMultipleChoice(item.libraryId,item.optionVoList,childItem, item)"
                    :value="i"
                    v-for="(childItem,i) in item.optionVoList" 
                    :key="item.libraryId +'-' + childItem.optionId">
                    <span>{{childItem.letter}}、{{childItem.optionName}}</span>
                  </a-checkbox>
                <!-- </a-checkbox-group> -->
              </div>
            </div>
          </div>
          <!-- 判断题 -->
          <div class="topic-list" v-if="paperForm.switchList.length">
            <div class="topic-item-title" v-html="paperForm.switchTitle"></div>
            <div class="question-item" :id="'switchItem' + item.libraryId" v-for="(item,index) in paperForm.switchList" :key="item.libraryId + 'switchItem'">
              <p class="question-name">{{radioTotal + checkedTotal + index + 1}}. {{item.title}}</p>
              <div class="option-child">
                <!-- <a-radio-group> -->
                  <a-radio class="option-item"
                    :disabled="paperDisabel || !network"
                    :checked="childItem.isCheked==1"
                    @click="onSingleChoice(item.libraryId, item.optionVoList, childItem, item)"
                    :value="i"
                    v-for="(childItem,i) in item.optionVoList" 
                    :key="item.libraryId +'-' + childItem.optionId">
                    <span>{{childItem.letter}}、{{childItem.optionName}}</span>
                  </a-radio>
                <!-- </a-radio-group> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 实时截取现场考试图片 -->
    <Camear :isCameraShow="true" @setDataURL="getDataURL" v-show="false" />
    <a-spin class="sping" :tip="loadTips" v-show="loading" />
    <a-modal v-model="isExamCodeShow" @ok="handleOk" width="423px" :maskClosable="false" :closable="false">
      <div class="exam-code">
        <h3>温馨提示</h3>
        <div class="content">
          <p class="font">{{textAlert}}</p>
          <div class="btn-box">
            <a-button type="primary" v-show="textBtnShow" :loading="loading" class="btn" @click="onSubmitSeconds()" ghost>提交<span v-show="isSubmitShow">({{readSecond}}s)</span></a-button>
            <a-button type="primary" class="btn" @click="onSubmitCheck()" >检查一下</a-button>
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal v-model="isTimeEndShow" @ok="handleOk" width="423px" :maskClosable="false" :closable="false">
      <div class="exam-code">
        <h3>温馨提示</h3>
        <div class="content">
          <p class="font">考试答题时间已到，试卷自动提交。</p>
          <div class="btn-box">
            <a-button type="primary" class="btn" style="margin-right:0" :loading="loading" @click="onSubmitReady()">确认({{downSecond}})</a-button>
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal v-model="isTipsShow" @ok="handleOk" width="423px" :maskClosable="false" :closable="false">
      <div class="exam-code">
        <h3>温馨提示</h3>
        <div class="content">
          <p class="font">系统监测到您已经第二次刷新了页面。按照考试规定，现对您本次考试进行自动交卷。如有疑问，请联系教务老师。</p>
          <div class="btn-box">
            <a-button type="primary" class="btn" style="margin-right:0" :loading="loading" @click="onSubmitReady()">确认({{downSecond}})</a-button>
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal v-model="isTipsFirstShow" @ok="handleOk" width="423px" :maskClosable="false" :closable="false">
      <div class="exam-code">
        <h3>温馨提示</h3>
        <div class="content">
          <p class="font">{{isTipsFirstTxt}}</p>
          <div class="btn-box">
            <a-button type="primary" class="btn" style="margin-right:0" @click="isTipsFirstShow=false">我知道了</a-button>
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal v-model="isDisNetWorkShow" @ok="handleOk" width="423px" :maskClosable="false" :closable="false">
      <div class="exam-code">
        <h3>温馨提示</h3>
        <div class="content">
          <p class="font">网络异常，请确认联网后再进行操作</p>
          <div class="btn-box">
            <a-button type="primary" class="btn" style="margin-right:0" @click="isDisNetWorkShow=false">检查网络</a-button>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { FunError } from '@/utils/fun.js'
import Camear from '@/components/CamearExam.vue'
export default {
  // 可用组件的哈希表
  components: {Camear},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      paperDisabel: false,
      loading:false,
      isSubmitShow: false,
      isExamCodeShow: false,
      isTimeEndShow:false,
      isTipsShow:false,
      isTipsFirstShow: false,
      isTipsFirstTxt:'',
      loadTips:'正在加载本场考试试卷，请耐心等待...',
      textAlert: '确认提交试卷吗？试卷提交成功后将不可以再次更改。',
      textBtnShow: true,
      paperForm: {
        radioList: [], // 单选
        checkedList: [], // 多选
        switchList: [], // 判断
      },
      firstTime: '',
      seconds:0,
      hour:'00',  // 时
      minute:'00',  // 分
      second:'00',  // 秒
      radioTotal:0, // 单选题量
      checkedTotal:0,  // 多选题量
      switchTotal:0,  // 判断题量

      radioSelectTotal:0, // 已选单选题量
      checkedSelectTotal:0,  // 已选多选题量
      switchSelectTotal:0,  // 已选判断题量

      libraryIdList: [],  // 存放所有试题的id

      submitForm: {
        duration: '00:00:01',  // 报名id
        examId: this.$store.state.userInfo.configId,  // 考试id
        examScore: 0,   // 得分
        examineeId: this.$store.state.userInfo.examineeid,  // 考生id
        paperId: this.$store.state.userInfo.paperid,  // 试卷id
        startTime: '',  // 开考时间
        endTime: '',  // 结束时间
        stemJson: [],  // 答题记录
        // examStatus:'',
        type: 1,  // 1.考试  2.模拟
        userPaperId: '' 
      },
      isNormal:false, // 是否正常提交,

      downSecond: 5,
      readSecond: 10,  // 确认交卷预留10秒

      incrementPaper: [],  // 增量试卷记录

      noticeList:[], // 考试须知

      network:true, // 联网状态
      isDisNetWorkShow: false,
    }
  },
  // 事件处理器
  methods: {
    // 定时保存数据
    scheduledSave(){
      this.timeout3 = setInterval(() => {
      // this.timeout3 = setTimeout(() => {
        this.$ajax({
          method: 'PUT',
          url:"/exam/system/edit-paper",
          params: this.onFormatData(2)
        }).then(res => {
          if (res.code == 200 && res.success) {
            if(!this.submitForm.userPaperId){
              this.getPaperDetail(2)
            }  
          }
        }).catch(err=>{
          // this.$message.error('数据同步失败');
        })
      }, 20000);
    },
    // 交卷
    onSubmitPaper(){
      // 交卷时关闭实时保存数据功能
      clearTimeout(this.timeout2);
      clearTimeout(this.timeout3);
      this.loading = true
      this.$ajax({
        method: 'post',
        url:"/exam/system/submit/paper",
        params: this.onFormatData(1)
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          let obj = this.$store.state.userInfo
          obj.isSubmit = true
          obj.resultScore = res.data
          this.isTimeEndShow = false
          this.$store.commit("updateUserInfo", obj);  // 更新个人信息
 
          if(this.isNormal){  // 正常提交下，去成绩页
            if(res.data.scorePublishFlag) { // 无主观题去成绩结果页
              this.$router.push({ path: "/ExamView/ExamScore" });
            } else {  
              this.$router.push({ path: "/ExamView/ExamScoreNoPublished" });
            }
          }
        }else{
          this.$message.error(res.message);
        }
      })
    },

    // 定时截取现场考试照片
    getDataURL(e){
      this.$ajax({
        method: 'post',
        url:"/exam/system/add-picture",
        params: {
          examineeId: this.$store.state.userInfo.examineeid,
          picture: e,
        }
      })
    },

    onMousedown(event) {
      if (event.button == 0) {
        // alert("你点了左键" + lable);
      } else if (event.button == 1) {
        // alert("你点了滚轮" + lable);
      }else if (event.button == 2) {
        this.$message.warning('无法复制题目，请严格遵守考试纪律');
      }
    },

    // 格式化数据
    onFormatData(type){
      // 重置答题记录
      this.submitForm.stemJson = []
      this.submitForm.examScore = 0
      // const topicList = [...this.paperForm.radioList,...this.paperForm.checkedList,...this.paperForm.switchList]
      // type = 1  全部保存   2 增量保存
      let topicList = []
      if(type == 1){
        topicList = [...this.paperForm.radioList,...this.paperForm.checkedList,...this.paperForm.switchList]
      } else if (type == 2) {
        topicList = this.incrementPaper
      }
      let count = 0
      topicList.forEach((element, index) => {
        // 重组答题格式记录
        this.submitForm.stemJson.push(
          {
            libraryId: element.libraryId,  // 题id
            isCorrect: 0,  // 是否正确
            sort: this.submitForm.userPaperId ? null : index + 1,  // 题顺序
            score: element.score,  // 分值
            getScore: 0,  // 得分
            stemOptionJson: [],   // 选项
            stemId: element.stemId ?  element.stemId : ''
          }
        )

        count = 0
        element.optionVoList.forEach(child => {
          this.submitForm.stemJson[index].stemOptionJson.push({
            optionId: child.optionId,
            isCheked: child.isCheked,
            isCorrect: child.isCorrect,
            sort: child.sort,
            stemOptionId : child.stemOptionId ? child.stemOptionId : ''
          })
          if(element.isRadio){  // 判断和单项
            // 如果当前选项为正确选项，且已选，那就表示答对
            if((child.isCheked == 1) && (child.isCorrect == 1)){
              this.submitForm.stemJson[index].isCorrect = 1
              this.submitForm.stemJson[index].getScore = element.score
            }
          } else {  // 多选
            if(child.isCheked != child.isCorrect){
              count ++
            }
          }
        })

        if(!element.isRadio && count==0){
          this.submitForm.stemJson[index].isCorrect = 1
          this.submitForm.stemJson[index].getScore = element.score
        }
        // this.submitForm.examScore = this.submitForm.stemJson[index].getScore + this.submitForm.examScore 
        this.submitForm.stemJson[index].stemOptionJson = JSON.stringify(this.submitForm.stemJson[index].stemOptionJson)
      })

      this.fun_totalScore() // 算总分
      
      this.submitForm.stemJson = JSON.stringify(this.submitForm.stemJson)

      this.incrementPaper = []
      return this.submitForm   // 返回格式化后的表单数据
    },


    // 实时计算总分
    fun_totalScore(){
      let obj = {}
      // 重置答题记录
      obj.stemJson = []
      obj.examScore = 0
      const topicList = [...this.paperForm.radioList,...this.paperForm.checkedList,...this.paperForm.switchList]
      let count = 0
      topicList.forEach((element, index) => {
        // 重组答题格式记录
        obj.stemJson.push(
          {
            libraryId: element.libraryId,  // 题id
            isCorrect: 0,  // 是否正确
            sort: index + 1,  // 题顺序
            score: element.score,  // 分值
            getScore: 0,  // 得分
            stemOptionJson: [],   // 选项
            stemId: element.stemId ?  element.stemId : ''
          }
        )

        count = 0
        element.optionVoList.forEach(child => {
          obj.stemJson[index].stemOptionJson.push({
            optionId: child.optionId,
            isCheked: child.isCheked,
            isCorrect: child.isCorrect,
            sort: child.sort,
            stemOptionId : child.stemOptionId ? child.stemOptionId : ''
          })
          if(element.isRadio){  // 判断和单项
            // 如果当前选项为正确选项，且已选，那就表示答对
            if((child.isCheked == 1) && (child.isCorrect == 1)){
              obj.stemJson[index].isCorrect = 1
              obj.stemJson[index].getScore = element.score
            }
          } else {  // 多选
            if(child.isCheked != child.isCorrect){
              count ++
            }
          }
        })

        if(!element.isRadio && count==0){
          obj.stemJson[index].isCorrect = 1
          obj.stemJson[index].getScore = element.score
        }
        this.submitForm.examScore = obj.stemJson[index].getScore + this.submitForm.examScore 
      })
    },

    // 点击左侧题号定位到右侧题目--锚点
    onAnchor(name, id){
      const returnEle = document.querySelector('#' + name + id);
      if (!!returnEle) {
        returnEle.scrollIntoView(true); // true 是默认的
      }
    },

    // 获取考试试题
    getPaperList(){
      this.$ajax({
        method: 'get',
        url:"/exam/system/exam-paper",
        params:{
          configId: this.$store.state.userInfo.configId,
          paperid: this.$store.state.userInfo.paperid,
          signupUserId: this.$store.state.userInfo.signupuserid
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          // 遍历所有单选题，给每道题选项加一个是否选中标识
          res.data.radioList.forEach(element => {
            element.isRadio = true
            element.optionVoList.forEach(child => {
              child.isCheked = 0
            })
          })

          // 遍历所有多选题，给每道题选项加一个是否选中标识
          res.data.checkedList.forEach(element => {
            element.isRadio = false
            element.optionVoList.forEach(child => {
              child.isCheked = 0
            })
          })

          // 遍历所有判断题，给每道题选项加一个是否选中标识
          res.data.switchList.forEach(element => {
            element.isRadio = true
            element.optionVoList.forEach(child => {
              child.isCheked = 0
            })
          })
          this.paperForm = res.data
          // 获取各个题型有多少道题
          this.radioTotal = res.data.radioList.length
          this.checkedTotal = res.data.checkedList.length
          this.switchTotal = res.data.switchList.length
          
          // 当第二次结束时间没有的时候，取第一次的时间
          this.timeToSec(res.data.lastTime == '00:00:00' ? (res.data.firstTime > res.data.examendtime ? res.data.examendtime : res.data.firstTime) : res.data.lastTime)

          this.firstTime = res.data.firstTime
          
          // 首次答题，需要先保存下试卷
          this.loading = true
          this.$ajax({
            method: 'PUT',
            url:"/exam/system/edit-paper",
            params: this.onFormatData(1)
          }).then(res => {
            if (res.code == 200 && res.success) {
              this.getPaperDetail(2)
            }
          })
        }
      })
    },
    
    // 首次进入页面，先查看是否有提交过的试卷记录
    getPaperDetail(e){
      if(e==1){this.loading = true}
      this.$ajax({
        method: 'get',
        url:"/exam/system/paper/deatil?examineeId=" + this.$store.state.userInfo.examineeid,
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.loading = false
          this.paperDisabel = false
          if(JSON.stringify(res.data) == "{}"){  //如果答题记录为空，则表示是第一次答题，则去请求试卷列表
            this.paperDisabel = true
            this.loading = true

            this.getPaperList()

            // 需要先给考试开始和结束时间分别赋值
            this.$ajax({
              method: 'get',
              url:"/exam/system/get/time",
            }).then(res => {
              this.submitForm.startTime = res
              this.submitForm.endTime = res
            })
          } else {  // 有答题记录情况下，把请求到的数据做一下格式化处理，用于列表渲染
            // 遍历所有单选题，给每道题选项加一个是否选中标识
            res.data.radioList.forEach(element => {
              element.isRadio = true
              element.optionVoList.forEach(child => { // 将已选中的加到选中数组
                if(child.isCheked == 1){
                  this.libraryIdList.push(element.libraryId)
                }
              })
            })

            // 遍历所有多选题，给每道题选项加一个是否选中标识
            res.data.checkedList.forEach(element => {
              element.isRadio = false
              element.optionVoList.forEach(child => {  // 将已选中的加到选中数组
                if(child.isCheked == 1){
                  if(this.libraryIdList.indexOf(element.libraryId) < 0){
                    this.libraryIdList.push(element.libraryId)
                  }
                }
              })
            })

            // 遍历所有判断题，给每道题选项加一个是否选中标识
            res.data.switchList.forEach(element => {
              element.isRadio = true
              element.optionVoList.forEach(child => { // 将已选中的加到选中数组
                if(child.isCheked == 1){
                  this.libraryIdList.push(element.libraryId)
                }
              })
            })
            this.paperForm = res.data
            // 获取各个题型有多少道题
            this.radioTotal = res.data.radioList.length
            this.checkedTotal = res.data.checkedList.length
            this.switchTotal = res.data.switchList.length

            this.selectTopic()
            clearTimeout(this.timeout);
            this.timeToSec(res.data.lastTime == '00:00:00' ? (res.data.firstTime > res.data.examendtime ? res.data.examendtime : res.data.firstTime) : res.data.lastTime)
            this.firstTime = res.data.firstTime
            this.submitForm.userPaperId = res.data.userPaperId
            this.submitForm.startTime = res.data.startTime

            // 检测到刷新过时，要交卷
            let obj = this.$store.state.userInfo
            if(obj.refreshNum == 1){
              this.funWatchError(1,2)
              this.isTipsFirstTxt = '系统监测到您已刷新页面一次，再次刷新将会自动提交您的试卷，请注意考试纪律'
              this.isTipsFirstShow=true
            } else if (obj.refreshNum >= 2){
              this.funWatchError(2,2)
              this.isTipsShow = true
              this.downTime()
            }
          }
        }
      })
    },
    // 作答剩余时间
    timeToSec(time) {
      var hour = time.split(':')[0]
      var min = time.split(':')[1]
      var sec = time.split(':')[2]
      var s = Number(hour * 3600) + Number(min * 60) + Number(sec)
      this.seconds = s
      if(this.seconds){
        this.timeout = setInterval(() => {
          if(!this.network){return} // 断网时不减考试时间
          this.seconds --
          if(this.seconds == 0) { // 剩余时间为0时 弹出时间到的弹窗，并清除定时器
            this.isTimeEndShow = true
            this.isExamCodeShow = false
            clearTimeout(this.timeout5)
            clearTimeout(this.timeout)
            this.funWatchError(6,1)
            this.downTime()
          }
          if(!this.loading){
            this.countDown();
          }
        }, 1000);
      } else {
        this.onSubmitReady()
      }
    },

    // 结束提示语
    downTime(){
      this.timeout4 = setInterval(() => {
        this.downSecond --
        if(this.downSecond == 0){
          clearTimeout(this.timeout4)
          this.onSubmitReady()
        }
      }, 1000);
    },

    // 开考倒计时
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60));
      d = d < 10 ? "0" + d : d;
      let h = parseInt((this.seconds / (60 * 60)) % 24);
      this.hour = h < 10 ? "0" + h : h;
      let m = parseInt((this.seconds / 60) % 60);
      this.minute = m < 10 ? "0" + m : m;
      let s = parseInt(this.seconds % 60);
      this.second = s < 10 ? "0" + s : s;
    }, //定时器没过1秒参数减1

    // 实时获取结束时间，用于预防异常退出，获取不到结束时间
    getEndTime(){
      this.timeout2 = setInterval(() => {
        this.$ajax({
          method: 'get',
          url:"/exam/system/get/time",
        }).then(res => {
          if(!this.submitForm.startTime){
            this.submitForm.startTime = res
          }
          this.submitForm.endTime = res
          this.sumTotalTime()
        })
      }, 19000);
    },

    // 试卷交卷需要将整个答题做最后检查，如有未作答题，不准交卷
    onSaveCheck(){
      if(!this.network){
        this.$message.warning('当前网络异常，无法提交试卷，请检查网络后重试');
        return
      }
      this.isExamCodeShow = true
      // 校验单选题
      if(this.paperForm.radioList.length){
        let radioCheckArry = []
        let isCheck = false
        this.paperForm.radioList.forEach((item,index) => {
          isCheck = false
          item.optionVoList.forEach(subItem => {
            if(subItem.isCheked == 1){
              isCheck = true
            }
          });
          if(!isCheck){
            radioCheckArry.push(item.libraryId)
          }
        });
        if(radioCheckArry.length){
          this.textAlert = '请答完全部题目后再交卷。'
          this.textBtnShow = false
          this.onAnchor('radioItem',radioCheckArry[0])
          return
        }
      }
      // 校验多选
      if(this.paperForm.checkedList.length){
        let radioCheckArry = []
        let radioCheckIndex = []
        let isCheck = false
        let checkCount = 0
        this.paperForm.checkedList.forEach((item,index) => {
          isCheck = false
          checkCount = 0
          item.optionVoList.forEach(subItem => {
            if(subItem.isCheked == 1){
              isCheck = true
              checkCount = checkCount + 1
            }
          });
          if(!isCheck){
            radioCheckArry.push(item.libraryId)
          }
          if(checkCount < 2){
            radioCheckIndex.push({
              index: this.radioTotal + index + 1,
              libraryId: item.libraryId
            })
          }
        });
        if(radioCheckArry.length){
          this.textAlert = '请答完全部题目后再交卷'
          this.textBtnShow = false
          this.onAnchor('checkedItem',radioCheckArry[0])
          return
        }
        if(radioCheckIndex.length){
          this.textAlert = `第 ${radioCheckIndex[0].index} 题是多选项，请至少选择两个选项`
          this.textBtnShow = false
          this.onAnchor('checkedItem',radioCheckIndex[0].libraryId)
          return
        }
      }
      // 校验判断题
      if(this.paperForm.switchList.length){
        let radioCheckArry = []
        let isCheck = false
        this.paperForm.switchList.forEach((item,index) => {
          isCheck = false
          item.optionVoList.forEach(subItem => {
            if(subItem.isCheked == 1){
              isCheck = true
            }
          });
          if(!isCheck){
            radioCheckArry.push(item.libraryId)
          }
        });
        if(radioCheckArry.length){
          this.textAlert = '请答完全部题目后再交卷'
          this.textBtnShow = false
          this.onAnchor('switchItem',radioCheckArry[0])
          return
        }
      }
      this.textAlert = '确认提交试卷吗？试卷提交成功后将不可以再次更改。'
    },

    // 中断提交试卷功能
    onSubmitCheck(){
      this.isExamCodeShow = false 
      this.isSubmitShow = false
      this.readSecond = 10
      clearTimeout(this.timeout5)
    },

    // 考试交卷前读秒
    onSubmitSeconds(){
      if(this.isSubmitShow) return
      this.readSecond = 10
      this.isSubmitShow = true
      this.timeout5 = setInterval(() => {
          this.readSecond --
          if(this.readSecond <= 0) {
            this.isSubmitShow = false
            clearTimeout(this.timeout5)
            this.funWatchError(5,1)
            this.onSubmitReady()
          }
        }, 1000);
    },

    // 提交试卷前触发
    onSubmitReady(){
      this.getExamSystemTime()
      this.isNormal = true
    },

    // 提交试卷前先获获取下系统时间
    getExamSystemTime(){
      clearTimeout(this.timeout4)
      this.loadTips = '正在交卷，请稍等...'
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/exam/system/get/time",
      }).then(res => {
        this.submitForm.endTime = res
        this.sumTotalTime()
        this.loading = false
        if(!this.submitForm.startTime){
          this.submitForm.startTime = res
        }
        this.onSubmitPaper()
      })
    },

    // 计算考试时长
    sumTotalTime(){
      // 兼容ios设备 要把日期格式化
      // let date1 = new Date((this.submitForm.startTime).replace(/-/g,'/'))
      // let date2 = new Date((this.submitForm.endTime).replace(/-/g,'/'))
      let endtime = this.hour + ':' + this.minute + ':' + this.second
      let date1 = new Date('2022/08/13 ' + endtime)
      let date2 = new Date('2022/08/13 ' + this.firstTime)

      let s1 = date1.getTime(), s2 = date2.getTime();
      let total = (s2 - s1)/1000;

      let day = parseInt(total / (24*60*60));//计算整数天数
      let afterDay = total - day*24*60*60;//取得值算出天数后剩余的转秒数
      let  hour = parseInt(afterDay/(60*60));//计算整数小时数
      let afterHour = total - day*24*60*60 - hour*60*60;//取得算出小时数后剩余的秒数
      let min = parseInt(afterHour/60);//计算整数分
      let afterMin = total - day*24*60*60 - hour*60*60 - min*60;//取得算出分后剩余的秒数

      hour = hour < 10 ? '0' + hour : hour
      min = min < 10 ? '0' + min : min
      afterMin = afterMin < 10 ? '0' + afterMin : afterMin
  
      let examTime = hour + ':' + min + ':' + afterMin
      this.submitForm.duration = examTime  //  考试用时时长
    },


    // 多选时触发点击时触发，将题目的id暂存到已答数组
    onMultipleChoice(parseID,optionVoList, childItem, v){
      childItem.isCheked = childItem.isCheked == 1 ? 0 : 1
      // 定义一个变量，用于检测多选题是否有选中
      let isSelect = false
      optionVoList.forEach(element => {
        if(element.isCheked == 1){
          isSelect = true
        }
      })
      // 如果有至少选中一个，则表示该题已答
      if(isSelect){
        if(this.libraryIdList.indexOf(parseID) < 0){
          this.libraryIdList.push(parseID)
        }
      } else {
        let i = this.libraryIdList.indexOf(parseID)
        this.libraryIdList.splice(i, 1)
      }

      this.incrementPaper = this.incrementPaper.filter(item=>{return item.libraryId != parseID})
      this.incrementPaper.push(v)

      this.selectTopic()
    },

    // 单选题和判断题点击时触发，将题目的id暂存到已答数组
    onSingleChoice(parseID,optionVoList, childItem, v){
      if(this.libraryIdList.indexOf(parseID) < 0){
        this.libraryIdList.push(parseID)
      }

      // 将所选题的选项全部重置为未选
      optionVoList.forEach(element => {
        element.isCheked = 0
      });
      // 将点击的选项 设为已选状态
      childItem.isCheked = 1

      this.incrementPaper = this.incrementPaper.filter(item=>{return item.libraryId != parseID})
      this.incrementPaper.push(v)

      this.selectTopic()
    },

    // 计算已选的题量
    selectTopic() {
      this.radioSelectTotal = 0
      this.checkedSelectTotal = 0
      this.switchSelectTotal = 0

      this.paperForm.radioList.forEach(element => {
        if(this.libraryIdList.indexOf(element.libraryId) != -1){
          this.radioSelectTotal ++
        }
      })

      this.paperForm.checkedList.forEach(element => {
        if(this.libraryIdList.indexOf(element.libraryId) != -1){
          this.checkedSelectTotal ++
        }
      })

      this.paperForm.switchList.forEach(element => {
        if(this.libraryIdList.indexOf(element.libraryId) != -1){
          this.switchSelectTotal ++
        }
      })
    },


    handleOk(){},
    beforeUnloadHandler(e) {
      let obj = this.$store.state.userInfo
      obj.refreshNum = Number(obj.refreshNum) + 1
      obj.screenNum = Number(obj.screenNum) - 1
      this.$store.commit("updateUserInfo", obj);  // 更新个人信息
      // e.returnValue = "刷新或离开此页面？" // 此处返回任意字符串，不返回null即可，不能修改默认提示内容

      // this.$router.push('/')
    },

    // 监听是否在本页面
    monitor(e) {
      let isExist = e.target.visibilityState
      if (isExist === 'visible') {
        // 进入页面！
      } else {
        let obj = this.$store.state.userInfo
        obj.screenNum = Number(obj.screenNum) + 1
        this.$store.commit("updateUserInfo", obj);  // 更新个人信息
        if(obj.screenNum == 1){  // 第一次切屏提示一次
          this.funWatchError(3,2)
          this.isTipsFirstTxt = '系统监测到您已切屏页面一次，再次切屏将会自动提交您的试卷，请注意考试纪律'
          this.isTipsFirstShow = true
        } else if (obj.screenNum >= 2) { // 第二次切屏直接强制交卷
          this.funWatchError(4,2)
          this.onSubmitReady();
        }
        // 离开页面
      }
    },

    // 监听异常记录
    funWatchError(type, status){
      if(this.loading) return
      this.$ajax({
        method: "POST",
        url: "/exam/abnor-oper/save?content=" + FunError(type) + '&examineeId=' + this.$store.state.userInfo.examineeid + '&status=' + status,
      })
    },

    // 获取考试须知
    getExamInstructions(){
      this.$ajax({
        method: "get",
        url: "/exam/system/getExamSystemDesc",
        params: {
          configId: this.$store.state.userInfo.configId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success && res.message) {
          // 考试须知一并返回，需按#处理分割字符
          this.noticeList = res.message.split('#')
        } else {
          this.noticeList=[]
        }
      });
    },

    disableBrowserBack() {
      history.pushState(null, null, document.URL);
    }
    
  },
  // 生命周期-实例创建完成后调用
  created () { 
    // 如果已经提交试卷，将不会再进入到考试系统，路由默认定向试卷列表页
    // if(this.$store.state.userInfo.isSubmit){
    //   // this.$router.push({ path: "/ExamView/ExamHome" });
    //   this.$router.push({ path: "/" });
    //   this.isNormal = true
    //   return
    // }

    // 监听是否刷新和关闭浏览器
    window.addEventListener("beforeunload", this.beforeUnloadHandler, false)

    // 监听是否离开页面
    document.addEventListener('visibilitychange', this.monitor)

    this.getPaperDetail(1)

    // 考试须知
    this.getExamInstructions()
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.getEndTime() // 实时获取考试结束时间

    this.scheduledSave()  // 定时保存答题数据

    // 禁止复制
    this.$nextTick(() => {
      document.onselectstart = new Function("event.returnValue=false");
    })

    // 禁用浏览器返回键
    history.pushState(null, null, document.URL);
    window.addEventListener('popstate', this.disableBrowserBack);

    // 检测断网
    window.addEventListener("offline", () => {
      this.tiemID = setTimeout(() => {
        this.network = false
        this.isDisNetWorkShow = true
        // this.$message.error('网络异常，请确认联网后再进行操作');
      }, 60000);
    });
    window.addEventListener("online", () => {
      this.network = true
      this.isDisNetWorkShow = false
      clearTimeout(this.tiemID);
      // this.$message.success('网络连接正常');
    })
  },
  // 生命周期-实例销毁离开前调用
  beforeDestroy () {
    clearTimeout(this.timeout);
    clearTimeout(this.timeout2);
    clearTimeout(this.timeout3);
    clearTimeout(this.timeout4);
    clearTimeout(this.timeout5)

    let obj = this.$store.state.userInfo
    obj.backNum = Number(obj.backNum) + 1

    // 非正常提交时触发
    if(!this.isNormal){
      if(obj.backNum >= 2){
        this.funWatchError(7,2) // 第二次退出页面，强制提交
        this.getExamSystemTime()
      } else {
        this.funWatchError(10,2) // 记录第一次退出页面
      }
    }
    if(obj.backNum >= 2){ // 记录交卷标识
      obj.isSubmit = true
    }
    
    this.$store.commit("updateUserInfo", obj);  // 更新个人信息
  },
  // 生命周期-实例销毁离开后调用
  destroyed () {
    document.removeEventListener('visibilitychange', this.monitor)
    window.removeEventListener("beforeunload", this.beforeUnloadHandler, false) 

    // 清除popstate事件 否则会影响到其他页面
    window.removeEventListener("popstate", this.disableBrowserBack, false);
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    isExamCodeShow(){
      if(!this.isExamCodeShow){
        setTimeout(()=>{
          this.textAlert = '确认提交试卷吗？试卷提交成功后将不可以再次更改。'
          this.textBtnShow = true
        },200)
      }
    }
  }
}
</script>

<style lang="less" scoped>
// 页面样式最外层
.exam-wrap{
  position: relative;
  min-width: 800px;
  background: #F8F8F8;
  height: 100vh;
  overflow: hidden;
  // 顶部区域
  .head-top-Div{
    width: 100%;
    position: relative;
    .head-img{
      width: 100%;
      max-height: 200px;
      min-height: 150px;
    }
    .head-center{
      position: absolute;
      left: 0;
      right:0;
      top: 20%;
      margin: 0 auto;
      width: 85%;
      max-width: 1200px;
      .side-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .side-tips-l{
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          >p{
            margin-top: 10px;
            color: rgba(255, 255, 255, 0.8);
          }
        }
        .side-time-r{
          display: flex;
          span{
            display: inline-block;
            text-align: center;
            line-height: 53px;
            border-radius: 12px;
          }
          .time{
            width: 266px;
            height: 53px;
            margin-right: 24px;
            background: rgba(0, 42, 136, 0.2);
            color: rgba(255, 255, 255, 0.8);
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              margin-right: 10px;
              width: 19px;
              height: 19px;
            }
            i{
              font-style: normal;
              color: #ffffff;
              font-size: 24px;
              margin-left: 10px;
            }
          }
          .paper-btn{
            width: 136px;
            height: 53px;
            font-size: 24px;
            color: #FFFFFF;
            background: #E7C13A;
            position: relative;
            overflow: hidden;
            cursor: pointer;
          }
          .paper-btn{
            &:hover::before{
              left: 100%;
            }
            &::before{
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              left: -100%;
              background: linear-gradient(90deg,transparent,hsla(0,0%,100%,.5),transparent);
              transition: all .5s;
            }
          }
        }
      }
    }
  }
  // 个人信息和作答区
  .answer-box{
    overflow: hidden;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    margin-top: -75px;
    height: calc(100% - 100px);
    // 左侧题号
    .side-number-left{
      width: calc(25% - 20px);
      height: calc(100% - 50px);
      overflow-y: scroll;
      margin-right: 20px;
      min-width: 275px;
      float: left;
      background: #FFFFFF;
      border-radius: 8px;
      padding: 16px;
      .title{
        font-weight: 500;
        color: #333333;
      }
      .info-inner{
        margin-top: 8px;
        border-radius: 8px;
        padding: 16px 12px;
        background: rgba(45,116,221, 0.08);
        .line{
          display: flex;
          // color: rgba(47,46,65, 0.8);
          color: #333333;
          line-height: 25px;
          font-weight: 500;
          font-size: 14px;
          span{
            display: inline-block;
            color: #2F2E41;
            min-width: 70px;
            font-weight: 400;
          }
        }
        .con {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 24px;
          &:nth-child(2) {
            margin-top: 8px;
          }
        }
      }
      .answer-sheet{
        display: flex;
        justify-content: space-between;
        margin: 30px 0 16px;
        .state{
          span{
            display: inline-block;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            &::after{
              content: '';
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 2px;
              margin-left: 2px;
              border: 1px solid #666666;
              position: relative;
              top: 1px;
            }
          }
          span:nth-child(1){
            margin-right: 8px;
            &::after{
              border: none;
              background: #2D74DD;
            }
          }
        }
      }
      .title-number{
        // height: calc(100vh - 300px);
        // overflow-y: scroll;
        padding-right: 10px;
        .number-inner{
          margin-bottom: 16px;
          .topic-type{
            font-size: 12px;
            .type{
              display: inline-block;
              text-align: center;
              line-height: 23px;
              color: #ffffff;
              width: 64px;
              height: 23px;
              background: #E28C09;
              border-radius: 21px;
              margin-right: 15px;
            }
          }
          .list{
            margin-top: 16px;
            .item{
              display: inline-block;
              text-align: center;
              margin-bottom: 16px;
              margin-right: 20px;
              width: calc((100% - 80px) / 5 );
              height: 30px;
              line-height: 30px;
              border-radius: 4px;
              font-size: 14px;
              color: #666666;
              border: 1px solid #CACACA;
              cursor: pointer;
            }
            .item:nth-child(5n){
              margin-right: 0;
            }
            .item-active{
              background: #2D74DD;
              color: #ffffff;
              border: 1px solid #2D74DD;
            }
          }
        }
      }
      // .title-number::-webkit-scrollbar {
      //   display: none;
      // }
      /* 设置滚动条的样式 */
      .title-number::-webkit-scrollbar {
        width:4px;
      }
      /* 滚动槽 */
      .title-number::-webkit-scrollbar-track {
        -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
        border-radius:10px;
        background:rgba(87, 54, 54, 0.1);
      }
      /* 滚动条滑块 */
      .title-number::-webkit-scrollbar-thumb {
        border-radius:10px;
        background:rgba(0,0,0,0.21);
        -webkit-box-shadow:inset006pxrgba(0,0,0,0.2);
      }
    }
    /* 设置滚动条的样式 */
    .side-number-left::-webkit-scrollbar {
      width:4px;
    }
    /* 滚动槽 */
    .side-number-left::-webkit-scrollbar-track {
      -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
      border-radius:10px;
      background:rgba(87, 54, 54, 0.1);
    }
    /* 滚动条滑块 */
    .side-number-left::-webkit-scrollbar-thumb {
      border-radius:10px;
      background:rgba(0,0,0,0.21);
      -webkit-box-shadow:inset006pxrgba(0,0,0,0.2);
    }

    // 右侧作答区
    .side-topic-left{
      float: right;
      max-width: calc(100% - 295px);
      width: 75%;
      background: #FFFFFF;
      border-radius: 8px;
      padding: 40px 40px 0;
      padding-right: 10px;
      height: calc(100% - 50px);
      .system-inner{
        overflow-y: scroll;
        padding-right: 5px;
        height: calc(100% - 20px);
        .topic-list{
          margin-bottom: 10px;
          .topic-item-title{
            margin-bottom: 16px;
            /deep/ h1{
              display: inline-block;
              font-size: 18px;
              font-weight: 600;
              color: #333333;
              span{
                font-size: 14px;
                font-weight: 400;
                color: rgba(47,46,65, 0.8);
              }
            }
          }
          .question-item{
            margin-left: 8px;
            margin-bottom: 12px;
            .question-name{
              font-size: 16px;
              line-height: 26px;
              font-weight: 400;
              color: #333333;
              margin-bottom: 20px;
            }
            .option-child{
              display: inline-block;
              margin-left: 12px;
              .option-item{
                display: block;
                white-space:normal;
                margin-left: 0;
                margin-bottom: 16px;
                line-height: 28px;
                color: #666666;
              }
            }
          }
        }
      }
      /* 设置滚动条的样式 */
      .system-inner::-webkit-scrollbar {
        width:4px;
      }
      /* 滚动槽 */
      .system-inner::-webkit-scrollbar-track {
        -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
        border-radius:10px;
        background:rgba(87, 54, 54, 0.1);
      }
      /* 滚动条滑块 */
      .system-inner::-webkit-scrollbar-thumb {
        border-radius:10px;
        background:rgba(0,0,0,0.21);
        -webkit-box-shadow:inset006pxrgba(0,0,0,0.2);
      }
    }
  }
  .sping{
    width: 245px;
    height: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -10px;
    margin-left: -10px;
  }
}

/deep/.ant-modal{
  top: 140px;
}

.exam-code{
  padding: 22px 30px;
  >h3{
    font-size: 18px;
    font-weight: 500;
    color: #2D74DD;
  }
  .content{
    margin: 0 30px;
    .font{
      margin-top: 10px;
      font-weight: 400;
      color: #666666;
    }
    .btn-box{
      text-align: right;
      margin-top: 20px;
      .btn{
        width: 90px;
      }
      .btn:nth-child(1){
        background: linear-gradient(180deg, #3681F0 0%, #2263C5 100%);
        margin-right: 20px;
      }
    }
  }
}

/deep/ .ant-modal-header{
  display: none;
}
/deep/ .ant-modal-body{
  padding: 0;
}
/deep/ .ant-modal-footer{
  display: none;
}

@media screen and (max-width: 1400px) {
  .exam-wrap .head-top-Div .head-center{
    width: 95%;
  }
  .answer-box{
    width: 95%;
  }
}
@media screen and (max-width: 1350px) {
  .exam-wrap .answer-box{
    margin-top: -5%;
  }
}
@media screen and (max-width: 1200px) {
  .exam-wrap .answer-box{
    margin-top: -4%;
  }
}
@media screen and (max-width: 700px) {
  // .exam-wrap .head-top-Div .head-center{
  //   width: 78%;
  // }
  // .answer-box{
  //   width: 95%;
  // }
}
</style>
